<template>
  <div id="page-user-list">
    <form
      method="POST"
      enctype="multipart/form-data"
      @submit.prevent="submitForm"
    >
      <div class="vx-row mb-6">
        <div class="vx-col w-full md:w-2/3">
          <div class="vx-card p-6 mb-6">
            <BlogForm></BlogForm>
          </div>
          <div class="vx-card p-6 mb-6">
            <SeoInfo></SeoInfo>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/3">
          <div class="vx-card p-6 mb-6">
            <BlogImage></BlogImage>
          </div>
          <div class="vx-card p-6 mb-6">
            <Package></Package>
          </div>
        </div>
      </div>

      <!-- Save & Reset Button -->
      <div class="vx-card p-4 mb-4">
        <SettingInfo></SettingInfo>

        <div class="flex flex-wrap items-center justify-end">
          <vs-button button="submit" class="ml-auto">Save Changes</vs-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import vSelect from "vue-select";
import BlogForm from "./common/BlogForm";
import SettingInfo from "./common/SettingInfo";
import BlogImage from "./common/Image";
import SeoInfo from "./common/SeoInfo";
import Package from "./common/Package";
import { BLOG } from "@/constant/entity-identifier";
import loaderMixin from "../../../mixins/loaderMixin";
import { mapGetters, mapActions } from "vuex";

import { updateImage, storeImage } from "@/services/image";

export default {
  components: {
    vSelect,
    BlogForm,
    SettingInfo,
    SeoInfo,
    Package,
    BlogImage,
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
      form: "blog/getFormObj",
      images: "blog/getImages",
      image_alt: "banner/getImageAlt",
    }),
    getBlogId() {
      return this.$route.params.blogId;
    },
  },
  created() {
    this.clearForm();
    this.clearValidationErrors();
    this.openLoading();
    this.fetchAndSetBlogById(this.getBlogId)
      .then(() => {
        this.closeLoading();
        // eslint-disable-next-line no-unused-vars
      })
      .catch((r) => this.closeLoading());
  },
  methods: {
    ...mapActions({
      updateBlogAction: "blog/updateBlogAction",
      fetchAndSetBlogById: "blog/fetchAndSetBlogById",
      clearForm: "blog/clearForm",
      clearValidationErrors: "validation/clearValidationErrors",
    }),
    prepareForm(data, type, method) {
      let formData = new FormData();
      formData.append("_method", method);
      formData.append("entity_id", data.id);
      formData.append("entity", type);
      method === "PATCH" ? formData.append("file_ids[]", data.image[0].id) : "";
      formData.append("images[]", this.images[0]);
      formData.append("alt[]", this.image_alt[0]);
      return formData;
    },
    submitForm() {
      this.openLoading();
      this.updateBlogAction({
        blogId: this.getBlogId,
        data: this.form,
      })
        .then(async (response) => {
          if (response.status === 202) {
            if (this.images.length > 0) {
              if (response.data.data.image) {
                await updateImage(
                  response.data.data.image[0].id,
                  this.prepareForm(response.data.data, BLOG, "POST")
                );
              } else {
                await storeImage(
                  this.prepareForm(response.data.data, BLOG, "POST")
                );
              }
              this.closeLoading();
            } else {
              this.closeLoading();
            }
            this.$vs.notify({
              text: "Blog updated successfully.",
              title: "Blog Updated",
              color: "success",
              position: "top-right",
            });
            this.$router.push("/blogs");
          }
        })
        .catch(() => this.closeLoading());
    },
  },
};
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
